import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import SettingsContext from "../../context/SettingsContext";
import AuthContext from "../../context/AuthContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { tokens } from "../../theme";
import {
    Container,
    Box,
    Grid,
    useTheme,
    Avatar,
    Typography,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import LoadingButton from "@mui/lab/LoadingButton";
import { LockOutlined } from "@mui/icons-material";
import { persian2english } from "../../components/ConvertDigits";

const boxStyle = (theme) => ({
    width: {
        xs: '90%',
        sm: '70%',
        md: '50%',
        lg: '35%',
        xl: '35%',
    },
    maxWidth: 'sm',
    padding: '20px',
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    /* From https://css.glass */
    background: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.8)",
    backdropFilter: theme.palette.mode === "light" ? "blur(5px)" : "blur(3px)",
    border: theme.palette.mode === "light" ? "1px solid rgba(0, 0, 0, 0.2)" : "1px solid rgba(255, 255, 255, 0.8)",
});

const ActiveAccount = () => {
    const { id: username } = useParams();
    const { t } = useTranslation();
    const {
        user_deleted,
        wrong_active_code,
        typography_active_account,
        active_code_time_out1,
        active_code_time_out2,
        resend_active_code1,
        resend_active_code2,
        resend_active_code3,
        submit
    } = t("lngActiveAccount");
    const navigate = useNavigate();
    const { postMethod } = useServices();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const { baseImageUrl, settings } = useContext(SettingsContext);
    const { newActiveCode, seconds, setSeconds } = useContext(AuthContext);
    const { handleOpenSnackbar } = useSnackbarContext();
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [activeCode, setActiveCode] = useState("");

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
        // eslint-disable-next-line
    }, [seconds]);

    const handleVerify = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                username: username,
                active_code: activeCode
            };

            const response = await postMethod("/account/active", obj);
            const data = await response?.data;

            if (data?.status === 200) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "success",
                    data.message
                );
                navigate("/login");
            } else {
                alert("Something went wrong!");
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    // "کاربر قبلاً حذف شده است"
                    user_deleted
                );
            }
            if (ex.response?.data?.errors?.popup) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    ex.response.data.errors.popup[0]
                );
            }
        }

        setLoadingUpdateButton(true);
    };

    const preventLetters = (to) => {
        const toRegex = /^[0-9]*$/;
        return toRegex.test(to);
    };

    const handleActiveCodeChange = (newValue) => {
        if (preventLetters(newValue)) {
            setActiveCode(persian2english(newValue));
        } else {
            handleOpenSnackbar(
                "bottom",
                "left",
                "error",
                // "کد فعال سازی وارد شده اشتباه است"
                wrong_active_code
            );
        }
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${baseImageUrl + settings.main_background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={boxStyle} borderRadius="20px">
                <Grid container className="top_to_bottom_animation">
                    <Grid item xs={12} sm={12} lg={12}>
                        <Box
                            component="div"
                            style={{
                                marginTop: "20px",
                                margin: "20px",
                            }}
                        >
                            <Container maxWidth="sm">
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Avatar>
                                        <LockOutlined sx={{
                                            color: mode === "light"
                                                ? colors.black
                                                : colors.white,
                                        }} />
                                    </Avatar>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: "4px",
                                        marginBottom: "35px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography component="h1" variant="h4" sx={{
                                        color: mode === "light"
                                            ? colors.black
                                            : colors.white,
                                    }}>
                                        {/* فعال سازی حساب کاربری */}
                                        {typography_active_account}
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <Typography variant="h6">
                                                {seconds > 0 ? (
                                                    <Box>
                                                        {/* زمان باقیمانده: <strong>{seconds}</strong> ثانیه */}
                                                        {active_code_time_out1}&nbsp;
                                                        <strong>{seconds}</strong>&nbsp;
                                                        {active_code_time_out2}
                                                    </Box>
                                                ) : (
                                                    <Typography
                                                        component="span"
                                                        variant="h6"
                                                    >
                                                        {/* جهت ارسال مجدد کد فعال سازی */}
                                                        {resend_active_code1}
                                                        <Typography
                                                            component="span"
                                                            variant="h6"
                                                            sx={{
                                                                cursor: "pointer",
                                                                fontWeight: 700,
                                                                color: mode === "light"
                                                                    ? colors.blueAccent[500]
                                                                    : colors.orangeAccent[500],
                                                            }}
                                                            onClick={() => newActiveCode(username)}
                                                        >
                                                            {/* &nbsp;اینجا&nbsp; */}
                                                            &nbsp;{resend_active_code2}&nbsp;
                                                        </Typography>
                                                        {/* کلیک کنید. */}
                                                        {resend_active_code3}
                                                    </Typography>
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <MuiOtpInput length={6} value={activeCode} onChange={handleActiveCodeChange} TextFieldsProps={{ placeholder: '*' }} style={{ direction: "ltr", textAlign: "left" }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <LoadingButton
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            sx={{
                                                borderRadius: 28,
                                                color: colors.white,
                                            }}
                                            loading={!loadingUpdateButton}
                                            disabled={
                                                !username ||
                                                activeCode.length < 6
                                            }
                                            onClick={handleVerify}
                                        >
                                            {/* ثبت */}
                                            {submit}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
};

export default ActiveAccount;
